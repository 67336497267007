import Vue from 'vue'
import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')
// window.Pusher.logToConsole = true

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: Vue._.get(process.env, 'VUE_APP_PUSHER_APP_KEY'),
  cluster: Vue._.get(process.env, 'VUE_APP_PUSHER_APP_CLUSTER'),
  encrypt: true,
})
